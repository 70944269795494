import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { demeterCompanyApi } from '../../../../Apis/Apis';
import applicationSettings from '../../../../Core/Settings/ApplicationSettings';
import {
    AddDemeterCompanyRequest,
    Currency,
    DemeterMarket,
    DemeterRegion,
    DemeterSubscriptionFrequency,
    UpdateDemeterCompanyRequest,
} from '../../../../Generated/Raven-Demeter';
import useApi from '../../../Apis/Hooks/useApiHook';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import CheckboxDropdownInput from '../../../Components/Form/Inputs/CheckboxDropdownInput';
import DatePickerInput from '../../../Components/Form/Inputs/DatePickerInput';
import SelectInput from '../../../Components/Form/Inputs/SelectInput';
import TextInput from '../../../Components/Form/Inputs/TextInput';
import PageHeaderWithBackNavigation from '../../../Components/Headers/PageHeaderWithBackNavigation';
import useSearchParameters from '../../../Components/Navigation/Hooks/useSearchParametersHook';
import useNotificationHook from '../../../Components/Notifications/useNotificationHook';
import RegexValidators from '../../../Core/Validation/RegexValidators';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import Permissions from '../Permissions/Permissions';
import styles from './CompaniesPage.module.scss';

type UpsertDemeterCompanyRequest = {
    name?: string | null;
    invoiceDate?: string | Date | null;
    subscriptionPrice?: number | null;
    subscriptionFrequency?: DemeterSubscriptionFrequency | string | null;
    billingCurrency?: Currency | string | null;
    billingRegion?: DemeterRegion | string | null;
    numberOfLicenses?: number | null;
    markets?: DemeterMarket[] | null;
};

const CompanyPage: React.FC = () => {
    const { demeterCompanyGuid } = useParams();
    const navigate = useNavigate();
    const [translations] = useLanguage();
    const [displayError, displaySuccess] = useNotificationHook();
    const [, setSearchParameters] = useSearchParameters();

    // Company hooks.
    const [company, setCompany] = useState<UpsertDemeterCompanyRequest>();
    const [, , getCompanyResponse] = useApi(() => {
        if (demeterCompanyGuid) {
            return demeterCompanyApi.getDemeterCompany(demeterCompanyGuid);
        }

        return null;
    });

    // Option hooks.
    const subscriptionFrequencyOptions = useMemo(
        () => [
            {
                value: undefined,
                label: '',
            },
            {
                value: DemeterSubscriptionFrequency.Monthly,
                label: translations.subscriptionFrequency.Monthly,
            },
            {
                value: DemeterSubscriptionFrequency.Quarterly,
                label: translations.subscriptionFrequency.Quarterly,
            },
            {
                value: DemeterSubscriptionFrequency.Yearly,
                label: translations.subscriptionFrequency.Yearly,
            },
        ],
        [translations],
    );

    const currencyOptions = useMemo(
        () => [
            {
                value: undefined,
                label: '',
            },
            {
                value: Currency.Usd,
                label: translations.currency.USD,
            },
            {
                value: Currency.Eur,
                label: translations.currency.EUR,
            },
        ],
        [translations],
    );

    const regionOptions = useMemo(
        () => [
            {
                value: undefined,
                label: '',
            },
            {
                value: DemeterRegion.America,
                label: translations.region.America,
            },
            {
                value: DemeterRegion.EuropeMiddleEastAndAfrica,
                label: translations.region.EuropeMiddleEastAndAfrica,
            },
            {
                value: DemeterRegion.AsiaPacific,
                label: translations.region.AsiaPacific,
            },
        ],
        [translations],
    );

    const marketOptions = useMemo(() => applicationSettings.markets.map((x) => ({ label: translations.market[x], value: x })), [translations]);

    // Actions hooks.
    const [isSaving, setIsSaving] = useState(false);
    const isValid = !!company?.name;

    // Load the Company if there is one.
    useEffect(() => {
        if (getCompanyResponse) {
            const responseCompany = getCompanyResponse.demeterCompany!;

            setCompany({
                ...responseCompany,
            });
        }
    }, [demeterCompanyGuid, getCompanyResponse]);

    const navigateBackToCompanies = () => {
        setSearchParameters({ tab: 'Companies' });
        navigate(-1);
    };

    const upsertCompany = async (): Promise<void> => {
        if (isValid) {
            try {
                if (company.invoiceDate) {
                    company.invoiceDate = formattingService.toApiDate(company.invoiceDate);
                } else {
                    company.invoiceDate = null;
                }

                if (demeterCompanyGuid) {
                    await demeterCompanyApi.updateDemeterCompany(demeterCompanyGuid, company as UpdateDemeterCompanyRequest);
                    displaySuccess(translations.companies.text.companySuccessfullyUpdated);
                } else {
                    await demeterCompanyApi.addDemeterCompany(company as AddDemeterCompanyRequest);
                    displaySuccess(translations.companies.text.companySuccessfullyAdded);
                }

                navigateBackToCompanies();
            } catch (error) {
                if (demeterCompanyGuid) {
                    displayError(translations.companies.errors.updateCompanyError);
                } else {
                    displayError(translations.companies.errors.addCompanyError);
                }
                setIsSaving(false);
            }
        } else {
            setIsSaving(false);
        }
    };

    return (
        <div className={styles.add_edit_company_page_container}>
            <PageHeaderWithBackNavigation
                handleBackNavigation={navigateBackToCompanies}
                title={demeterCompanyGuid ? company?.name ?? '' : translations.companies.title.add}
            />
            <div className={styles.add_edit_company_input}>
                <TextInput
                    title={translations.companies.fields.company}
                    placeholder={translations.companies.fields.company}
                    required
                    value={company?.name}
                    handleTextChange={(value) => setCompany({ ...company, name: value })}
                    validation={RegexValidators.AlphaNumericMinimumLength2}
                    errorMessage={translations.companies.errors.nameRequired}
                />
            </div>
            <div className={styles.add_edit_company_input}>
                <DatePickerInput
                    title={translations.companies.fields.invoiceDate}
                    required
                    value={company?.invoiceDate as Date}
                    handleDateChange={(value) => setCompany({ ...company, invoiceDate: value })}
                />
            </div>
            <div className={styles.add_edit_company_input}>
                <TextInput
                    title={translations.companies.fields.subscriptionPrice}
                    placeholder={translations.companies.fields.subscriptionPrice}
                    type="number"
                    value={company?.subscriptionPrice?.toString()}
                    handleTextChange={(value) => setCompany({ ...company, subscriptionPrice: +value })}
                    validation={RegexValidators.Numeric}
                />
            </div>

            <div className={styles.add_edit_company_input}>
                <SelectInput
                    title={translations.companies.fields.subscriptionFrequency}
                    value={company?.subscriptionFrequency}
                    options={subscriptionFrequencyOptions}
                    handleOptionChange={(value) => setCompany({ ...company, subscriptionFrequency: value })}
                />
            </div>
            <div className={styles.add_edit_company_input}>
                <SelectInput
                    title={translations.companies.fields.billingCurrency}
                    value={company?.billingCurrency}
                    options={currencyOptions}
                    handleOptionChange={(value) => setCompany({ ...company, billingCurrency: value })}
                />
            </div>
            <div className={styles.add_edit_company_input}>
                <SelectInput
                    title={translations.companies.fields.billingRegion}
                    value={company?.billingRegion}
                    options={regionOptions}
                    handleOptionChange={(value) => setCompany({ ...company, billingRegion: value })}
                />
            </div>
            <div className={styles.add_edit_company_input}>
                <TextInput
                    title={translations.companies.fields.numberOfLicenses}
                    placeholder={translations.companies.fields.numberOfLicenses}
                    type="number"
                    value={company?.numberOfLicenses?.toString()}
                    handleTextChange={(value) => setCompany({ ...company, numberOfLicenses: +value })}
                    validation={RegexValidators.Numeric}
                />
            </div>
            <div className={styles.add_edit_company_input}>
                <CheckboxDropdownInput
                    title={translations.users.fields.markets}
                    values={company?.markets ?? []}
                    options={marketOptions}
                    handleOptionSelect={(value: DemeterMarket[]) => setCompany({ ...company, markets: [...value] })}
                />
            </div>
            {demeterCompanyGuid && <Permissions demeterCompanyGuid={demeterCompanyGuid} />}
            <div className={styles.add_edit_company_submit_action_row}>
                <LinkButton title={translations.actions.cancel} onClick={navigateBackToCompanies} type={LinkButtonType.White} />
                <LinkButton
                    title={demeterCompanyGuid ? translations.actions.save : translations.actions.add}
                    type={LinkButtonType.Blue}
                    onClick={() => {
                        // Set this here and let the async upsertCompany do its job.
                        setIsSaving(true);
                        upsertCompany();
                    }}
                    disabled={!isValid || isSaving}
                />
            </div>
        </div>
    );
};

export default CompanyPage;
