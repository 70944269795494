import React, { useEffect, useMemo, useState } from 'react';
import { Currency, MarketIndicatorFactorDataModel, UnitOfMeasure } from '../../../../../Generated/Raven-Demeter';
import {
    leadingIndicatorTypeDefinitions,
    TechnicalParameters,
} from '../../../../Pages/Administration/MarketIndicatorsManagement/MarketIndicatorsManagementDefinitions';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { IChartDataSeries } from '../../ChartDefinitions';
import ChartWrapper from '../../ChartWrapper/ChartWrapper';
import { IMarketIndicatorChartProps } from '../MarketIndicatorChart';
import { defaultChartPalette } from '../MarketIndicatorChartColorPalette';
import { defaultTechnicalParameters } from '../MarketIndicatorChartDefaultParameters';
import MarketIndicatorChartRaw from '../MarketIndicatorChartRaw';
import marketIndicatorChartService from '../MarketIndicatorChartService';
import marketIndicatorTechnicalChartService from './MarketIndicatorTechnicalChartService';

export interface IMarketIndicatorTechnicalChartProps extends IMarketIndicatorChartProps {
    parameters?: TechnicalParameters;
}

const MarketIndicatorTechnicalChart: React.FC<IMarketIndicatorTechnicalChartProps> = (props: IMarketIndicatorTechnicalChartProps) => {
    const [translations, translate] = useLanguage();
    const [lineSeries, setLineSeries] = useState<IChartDataSeries[]>([]);

    useEffect(() => {
        const calculationData = marketIndicatorChartService.filterByYears(chartData, 10);
        const thisYearData = marketIndicatorChartService.filterByYears(calculationData, 1);
        const average1Data = marketIndicatorChartService.filterByYears(
            marketIndicatorTechnicalChartService.calculateMovingAverage(calculationData, daysToAverage1),
            1,
        );
        const average2Data = marketIndicatorChartService.filterByYears(
            marketIndicatorTechnicalChartService.calculateMovingAverage(calculationData, daysToAverage2),
            1,
        );
        const average3Data = marketIndicatorChartService.filterByYears(
            marketIndicatorTechnicalChartService.calculateMovingAverage(calculationData, daysToAverage3),
            1,
        );

        const newLinesSeries: IChartDataSeries[] = [
            {
                label: translations.text.thisYear,
                data: thisYearData,
            },
            {
                label: `${daysToAverage1} ${translations.text.dayMovingAverage}`,
                data: average1Data,
            },
            {
                label: `${daysToAverage2} ${translations.text.dayMovingAverage}`,
                data: average2Data,
            },
            {
                label: `${daysToAverage3} ${translations.text.dayMovingAverage}`,
                data: average3Data,
            },
        ];

        setLineSeries(newLinesSeries);
    }, [props.runTestMarketIndicatorFactorResponse, props.parameters]);

    const daysToAverage1 = useMemo(() => {
        const parameters = props.parameters ?? defaultTechnicalParameters;
        const value = Number(parameters.daysToAverage1);
        return value;
    }, [props.parameters]);

    const daysToAverage2 = useMemo(() => {
        const parameters = props.parameters ?? defaultTechnicalParameters;
        const value = Number(parameters.daysToAverage2);
        return value;
    }, [props.parameters]);

    const daysToAverage3 = useMemo(() => {
        const parameters = props.parameters ?? defaultTechnicalParameters;
        const value = Number(parameters.daysToAverage3);
        return value;
    }, [props.parameters]);

    const chartData = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.rows &&
            props.runTestMarketIndicatorFactorResponse.rows.length > 0
        ) {
            return marketIndicatorChartService.convertToChartData((props.runTestMarketIndicatorFactorResponse?.rows as MarketIndicatorFactorDataModel[]) ?? []);
        }
        return [];
    }, [props.runTestMarketIndicatorFactorResponse]);

    const isLoading = useMemo(
        () => props.isLoading || (!props.runTestMarketIndicatorFactorResponse && !props.showOnlyAsPopout),
        [(props.runTestMarketIndicatorFactorResponse, props.showOnlyAsPopout, props.isLoading)],
    );

    const title = useMemo(() => {
        if (props.title) {
            return props.title;
        }
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.commodityDisplayName) {
            const region = props.runTestMarketIndicatorFactorResponse?.subRegion
                ? translate(props.runTestMarketIndicatorFactorResponse?.subRegion)
                : translate(props.runTestMarketIndicatorFactorResponse?.regionDisplayName!);
            const tableDefinitionType =
                translations.tableDefinitionType[
                    leadingIndicatorTypeDefinitions.find((x) => x.value === props.runTestMarketIndicatorFactorResponse?.leadingIndicatorType)
                        ?.tableDefinitionType!
                ];
            const commdityName = props.runTestMarketIndicatorFactorResponse.commodityDisplayName;
            return [region, commdityName, tableDefinitionType].filter((x) => x !== null && x !== '').join(' ');
        }
        return '';
    }, [props.runTestMarketIndicatorFactorResponse]);

    const sourceTag = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags.length > 0
        ) {
            return `${props.runTestMarketIndicatorFactorResponse.dataSourceTags[0]}, ${translations.dataSource.StoneXCalculations}`;
        }
        return '';
    }, [props.runTestMarketIndicatorFactorResponse]);

    const currency = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.currency) {
            return props.runTestMarketIndicatorFactorResponse.currency;
        }
        return '' as Currency;
    }, [props.runTestMarketIndicatorFactorResponse]);

    const unitOfMeasure = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.unitOfMeasure) {
            return props.runTestMarketIndicatorFactorResponse.unitOfMeasure;
        }
        return '' as UnitOfMeasure;
    }, [props.runTestMarketIndicatorFactorResponse]);

    return (
        <ChartWrapper
            name="MarketIndicatorTechnicalChart"
            title={title}
            dataSourceTag={sourceTag}
            isLoading={isLoading}
            headerOptions={{
                showOnlyAsPopout: props.showOnlyAsPopout,
            }}
            showPopout={props.showPopout}
            setShowPopout={props.setShowPopout}
        >
            <MarketIndicatorChartRaw lineSeries={lineSeries} currencies={[currency]} unitOfMeasures={[unitOfMeasure]} colorPalette={defaultChartPalette} />
        </ChartWrapper>
    );
};

export default MarketIndicatorTechnicalChart;
