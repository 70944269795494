import React, { useEffect, useMemo, useState } from 'react';
import scssVariables from '../../../../../Config.module.scss';
import { Currency, MarketIndicatorFactorDataModel, UnitOfMeasure } from '../../../../../Generated/Raven-Demeter';
import {
    leadingIndicatorTypeDefinitions,
    SeasonalParameters,
} from '../../../../Pages/Administration/MarketIndicatorsManagement/MarketIndicatorsManagementDefinitions';
import formattingService from '../../../../Services/Formatting/FormattingService';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { IChartAreaRangeDataSeries, IChartDataSeries } from '../../ChartDefinitions';
import ChartWrapper from '../../ChartWrapper/ChartWrapper';
import { IMarketIndicatorChartProps } from '../MarketIndicatorChart';
import { defaultChartPalette, IMarketIndicatorChartColorPalette } from '../MarketIndicatorChartColorPalette';
import { defaultSeasonalParameters } from '../MarketIndicatorChartDefaultParameters';
import MarketIndicatorChartRaw from '../MarketIndicatorChartRaw';
import marketIndicatorChartService from '../MarketIndicatorChartService';
import styles from './MarketIndicatorSeasonalChart.module.scss';
import marketIndicatorSeasonalChartService from './MarketIndicatorSeasonalChartService';

export const seasonalChartPalette: IMarketIndicatorChartColorPalette = {
    areaRangeColors: [scssVariables.stonexLightGrayBlue],
    lineColors: defaultChartPalette.lineColors,
};

export interface IMarketIndicatorSeasonalChartProps extends IMarketIndicatorChartProps {
    parameters?: SeasonalParameters;
}

const MarketIndicatorSeasonalChart: React.FC<IMarketIndicatorSeasonalChartProps> = (props: IMarketIndicatorSeasonalChartProps) => {
    const [translations, translate] = useLanguage();
    const [lineSeries, setLineSeries] = useState<IChartDataSeries[]>([]);
    const [areaRangeSeries, setAreaRangeSeries] = useState<IChartAreaRangeDataSeries[]>([]);

    const dataFrequency = useMemo(() => props.runTestMarketIndicatorFactorResponse?.dataFrequency, [props.runTestMarketIndicatorFactorResponse]);

    useEffect(() => {
        const calculationData = marketIndicatorChartService.filterByYears(chartData, numberOfYears + 1);

        const thisYearData = marketIndicatorChartService.filterByYears(
            marketIndicatorSeasonalChartService.calculateThisYearData(calculationData, dataFrequency),
            1,
        );
        const lastYearData = marketIndicatorChartService.filterByYears(
            marketIndicatorSeasonalChartService.calculateLastYearData(calculationData, dataFrequency),
            1,
        );
        const seasonalAverageData = marketIndicatorChartService.filterByYears(
            marketIndicatorSeasonalChartService.calculateSeasonalAverageData(calculationData, numberOfYears, dataFrequency),
            1,
        );
        const rangeData = marketIndicatorChartService.filterByYears(
            marketIndicatorSeasonalChartService.calculateRangeData(calculationData, numberOfYears, dataFrequency),
            1,
        );

        const newLinesSeries: IChartDataSeries[] = [
            {
                label: translations.text.thisYear,
                data: thisYearData,
            },
            {
                label: translations.text.lastYear,
                data: lastYearData,
            },
            {
                label: `${numberOfYears} ${translations.text.yearlyAverage}`,
                data: seasonalAverageData,
            },
        ];

        const newAreaSeries: IChartAreaRangeDataSeries[] = [
            {
                label: `${numberOfYears} ${translations.text.yearRange}`,
                data: rangeData,
            },
        ];

        setLineSeries(newLinesSeries);
        setAreaRangeSeries(newAreaSeries);
    }, [props.runTestMarketIndicatorFactorResponse, props.parameters]);

    const numberOfYears = useMemo(() => {
        const parameters = props.parameters ?? defaultSeasonalParameters;
        const value = Number(parameters.yearsOfData);
        return value;
    }, [props.parameters]);

    const chartData = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.rows &&
            props.runTestMarketIndicatorFactorResponse.rows.length > 0
        ) {
            return marketIndicatorChartService.convertToChartData((props.runTestMarketIndicatorFactorResponse?.rows as MarketIndicatorFactorDataModel[]) ?? []);
        }
        return [];
    }, [props.runTestMarketIndicatorFactorResponse]);

    const isLoading = useMemo(
        () => props.isLoading || (!props.runTestMarketIndicatorFactorResponse && !props.showOnlyAsPopout),
        [props.runTestMarketIndicatorFactorResponse, props.showOnlyAsPopout, props.isLoading],
    );

    const title = useMemo(() => {
        if (props.title) {
            return props.title;
        }

        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.commodityDisplayName) {
            const region = props.runTestMarketIndicatorFactorResponse?.subRegion
                ? translate(props.runTestMarketIndicatorFactorResponse?.subRegion)
                : translate(props.runTestMarketIndicatorFactorResponse?.regionDisplayName!);
            const tableDefinitionType =
                translations.tableDefinitionType[
                    leadingIndicatorTypeDefinitions.find((x) => x.value === props.runTestMarketIndicatorFactorResponse?.leadingIndicatorType)
                        ?.tableDefinitionType!
                ];
            const commdityName = props.runTestMarketIndicatorFactorResponse.commodityDisplayName;
            return [region, commdityName, tableDefinitionType].filter((x) => x !== null && x !== '').join(' ');
        }
        return '';
    }, [props.runTestMarketIndicatorFactorResponse]);

    const sourceTag = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags.length > 0
        ) {
            return `${props.runTestMarketIndicatorFactorResponse.dataSourceTags[0]}, ${translations.dataSource.StoneXCalculations}`;
        }
        return '';
    }, [props.runTestMarketIndicatorFactorResponse]);

    const currency = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.currency) {
            return props.runTestMarketIndicatorFactorResponse.currency;
        }
        return '' as Currency;
    }, [props.runTestMarketIndicatorFactorResponse]);

    const unitOfMeasure = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.unitOfMeasure) {
            return props.runTestMarketIndicatorFactorResponse.unitOfMeasure;
        }
        return '' as UnitOfMeasure;
    }, [props.runTestMarketIndicatorFactorResponse]);

    const averageChange = useMemo(() => {
        if (chartData.length > 0) {
            const value = marketIndicatorSeasonalChartService.calculateSeasonalAverageChange(chartData, numberOfYears, dataFrequency);

            return formattingService.toNumberStringWithAutomaticDecimals(value);
        }

        return formattingService.toNumberStringWithAutomaticDecimals(0);
    }, [props.runTestMarketIndicatorFactorResponse]);

    const lastChange = useMemo(() => {
        if (chartData.length > 0) {
            const value = marketIndicatorSeasonalChartService.calculateLastChange(chartData, dataFrequency);
            return formattingService.toNumberStringWithAutomaticDecimals(value);
        }

        return formattingService.toNumberStringWithAutomaticDecimals(0);
    }, [props.runTestMarketIndicatorFactorResponse]);

    const hasAverageChangeFooter = useMemo(() => {
        if (!props.parameters?.subFactorCalculationTypes) {
            return false;
        }

        return props.parameters?.subFactorCalculationTypes.indexOf('VersesAverageChange') >= 0;
    }, [props.parameters?.subFactorCalculationTypes]);

    const footer = hasAverageChangeFooter && (
        <div className={styles.market_indicator_seasonal_chart_footer}>
            <span className={styles.market_indicator_seasonal_chart_footer_item}>
                {translations.text.averageChange}: {averageChange}
            </span>
            <span className={styles.market_indicator_seasonal_chart_footer_item}>
                {translations.text.lastChange}: {lastChange}
            </span>
        </div>
    );

    return (
        <ChartWrapper
            name="MarketIndicatorSeasonalChart"
            title={title}
            dataSourceTag={sourceTag}
            isLoading={isLoading}
            headerOptions={{
                showOnlyAsPopout: props.showOnlyAsPopout,
            }}
            showPopout={props.showPopout}
            setShowPopout={props.setShowPopout}
            footer={footer}
        >
            <MarketIndicatorChartRaw
                lineSeries={lineSeries}
                areaRangeSeries={areaRangeSeries}
                currencies={[currency]}
                unitOfMeasures={[unitOfMeasure]}
                colorPalette={seasonalChartPalette}
            />
        </ChartWrapper>
    );
};

export default MarketIndicatorSeasonalChart;
