import React, { useEffect, useMemo, useState } from 'react';
import { DemeterMarket, GetMarketIndicatorResponse, ListMarketIndicatorsResponse, MarketIndicatorOutlook } from '../../../Generated/Raven-Demeter';
import useLanguage from '../../Services/Language/useLanguageHook';
import useSearchParameters from '../Navigation/Hooks/useSearchParametersHook';
import styles from './MenuFileSelectors.module.scss';

interface IMenuByMarketIndicatorNavigationProps {
    listMarketIndicatorsResponse?: ListMarketIndicatorsResponse;
    getMarketIndicatorByRegionCommodityResponse?: GetMarketIndicatorResponse;
    isHedgeMonitorPro?: boolean;
}

const defaultMarket = DemeterMarket.All;

const MenuByMarketIndicatorNavigation: React.FC<IMenuByMarketIndicatorNavigationProps> = (props: IMenuByMarketIndicatorNavigationProps) => {
    // Application hooks and application constants.
    const [translations, translate] = useLanguage();
    const [selectedMarket, setSelectedMarket] = useState<DemeterMarket>(defaultMarket);
    const [searchParameters, setSearchParameters] = useSearchParameters();

    useEffect(() => {
        if (!props.listMarketIndicatorsResponse || searchParameters?.marketIndicatorGuid) {
            return;
        }

        const firstOfMarketOrFirstOfAll =
            props.listMarketIndicatorsResponse.rows!.find((x) => x.market === selectedMarket) ?? props.listMarketIndicatorsResponse.rows![0];

        setSearchParameters({ marketIndicatorGuid: firstOfMarketOrFirstOfAll?.marketIndicatorGuid! });
    }, [props.listMarketIndicatorsResponse]);

    const marketIndicatorMarketOptions = useMemo(() => {
        if (!props.listMarketIndicatorsResponse) {
            return [];
        }

        const options: { label: string; value: DemeterMarket }[] = [];

        (props.listMarketIndicatorsResponse as ListMarketIndicatorsResponse).rows?.forEach((x) => {
            if (options.find((y) => y.value === x.market)) {
                return;
            }

            options.push({ label: translations.market[x.market!], value: x.market! });
        });

        options.push({ label: translations.market[DemeterMarket.All], value: DemeterMarket.All });

        return options;
    }, [props.listMarketIndicatorsResponse, props.getMarketIndicatorByRegionCommodityResponse, translations]);

    return (
        <>
            <div className={styles.menu_by_market_tabs}>
                {marketIndicatorMarketOptions.map((x) => (
                    <button
                        className={selectedMarket === x.value ? styles.menu_by_market_tab_selected : styles.menu_by_market_tab}
                        type="button"
                        onClick={() => {
                            setSelectedMarket(x.value);
                            setSearchParameters({
                                marketIndicatorGuid: props.listMarketIndicatorsResponse?.rows!.filter((y) => x.value === y.market)[0].marketIndicatorGuid!,
                            });
                        }}
                        data-testid={`MarketIndicatorMarket${x.value}`}
                    >
                        {x.label}
                    </button>
                ))}
            </div>
            <div className={styles.menu_by_market}>
                {props.listMarketIndicatorsResponse?.rows
                    ?.filter((x) => selectedMarket === x.market || selectedMarket === DemeterMarket.All)
                    ?.filter((x) => {
                        const isAvailableInHedgeMonitorPro = x.outlook !== MarketIndicatorOutlook.Unavailable && x.hedgeMonitorProOutlook && x.precipiceOutlook;
                        return !props.isHedgeMonitorPro || (props.isHedgeMonitorPro && isAvailableInHedgeMonitorPro);
                    })
                    .map((marketIndicator) => (
                        <button
                            type="button"
                            onClick={() => setSearchParameters({ marketIndicatorGuid: marketIndicator.marketIndicatorGuid })}
                            className={
                                marketIndicator.marketIndicatorGuid === searchParameters.marketIndicatorGuid
                                    ? styles.menu_by_market_button_selected
                                    : styles.menu_by_market_button
                            }
                            data-testid={`MenuByMarketIndicatorNavigation${marketIndicator.displayName}`}
                        >
                            {translate(marketIndicator.displayName ?? '')}
                        </button>
                    ))}
            </div>
        </>
    );
};

export default MenuByMarketIndicatorNavigation;
