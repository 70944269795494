import { useEffect, useMemo } from 'react';
import {
    DemeterCommodity,
    DemeterMarket,
    DemeterRegion,
    DemeterSubRegion,
    DemeterTableDefinitionType,
    ExchangeType,
} from '../../../../../../../Generated/Raven-Demeter';
import useSymbolsApi from '../../../../../../Apis/Hooks/useSymbolsApiHook';
import useTableDefinitionsApi from '../../../../../../Apis/Hooks/useTableDefinitionsApiHook';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IDataSelectorCommodityDropdownProps {
    market: DemeterMarket;
    fundamentalCategory: DemeterTableDefinitionType | null;
    region?: DemeterRegion;
    subRegion?: DemeterSubRegion;
    commodity?: DemeterCommodity;
    extraParameters: string | null | undefined;
    exchange?: ExchangeType;
    handleChange: (commodity?: DemeterCommodity, extraParameters?: string, subRegion?: DemeterSubRegion) => void;
}

const defaultExtraParameters = 1;

const DataSelectorCommodityDropdown: React.FC<IDataSelectorCommodityDropdownProps> = (props: IDataSelectorCommodityDropdownProps) => {
    const [translations, translate] = useLanguage();
    const symbols = useSymbolsApi();
    const tableDefinitions = useTableDefinitionsApi(props.fundamentalCategory!, true, props.market);
    const isPricesCategory = props.fundamentalCategory === DemeterTableDefinitionType.CommodityPricesTable;
    const isFuturesCategory = props.fundamentalCategory === DemeterTableDefinitionType.CommodityFuturesTable;

    const commodityOptions = useMemo(() => {
        if (!props.region || (!tableDefinitions && !isFuturesCategory)) {
            return [];
        }

        if (isFuturesCategory && props.exchange) {
            return symbols
                ?.filter((x) => x.exchange === props.exchange && x.symbolCategory === props.market)
                .map((x) => ({
                    label: translate(x.displayName),
                    value: {
                        commodity: x.commodity,
                        extraParameters: undefined,
                        subRegion: undefined,
                    },
                }));
        }

        const productDefinitions = tableDefinitions
            ?.find((x) => x.region === props.region)
            ?.demeterTableDefinitionGroups.flatMap((tableDefinitionGroup) => {
                // If we are on physical prices, we want all subregions for a given region.
                // Otherwise filter the commodities for the given sub-region selected.
                if (!isPricesCategory && props.subRegion && !tableDefinitionGroup.subRegions.includes(props.subRegion)) {
                    return [];
                }

                const groupLevelCommodity = {
                    label: translate(tableDefinitionGroup.displayName),
                    value: {
                        commodity: tableDefinitionGroup.commodity,
                        extraParameters: tableDefinitionGroup.extraParameters ?? undefined,
                        subRegion: undefined,
                    },
                };

                const regularLevelCommodities = tableDefinitionGroup.demeterTableDefinitions.flatMap((x) => {
                    if (!isPricesCategory && props.subRegion && !x.subRegions?.includes(props.subRegion)) {
                        return [];
                    }

                    return [
                        {
                            label: `${translate(tableDefinitionGroup.displayName)} - ${translate(x.displayName)}`,
                            value: {
                                commodity: x.commodity,
                                extraParameters: x.extraParameters ?? undefined,
                                subRegion: x.subRegions ? x.subRegions[0] : undefined,
                            },
                        },
                    ];
                });

                if (groupLevelCommodity.value.commodity && regularLevelCommodities.length > 0 && !isPricesCategory) {
                    return [groupLevelCommodity, ...regularLevelCommodities];
                }

                if (regularLevelCommodities.length > 0) {
                    return regularLevelCommodities;
                }

                return groupLevelCommodity;
            });

        return productDefinitions;
    }, [props.region, props.exchange, props.subRegion, tableDefinitions, symbols, isFuturesCategory]);

    useEffect(() => {
        if (!commodityOptions || commodityOptions.length === 0) {
            return;
        }

        let matchingCommodity = commodityOptions.filter(
            (x) =>
                x.value.commodity === props.commodity &&
                (x.value.extraParameters === props.extraParameters || (!x.value.extraParameters && !props.extraParameters) || isFuturesCategory),
        );

        if (isPricesCategory && matchingCommodity.length > 0) {
            matchingCommodity = matchingCommodity.filter((x) => x.value.subRegion === props.subRegion);
        }

        if (matchingCommodity.length > 0) {
            return;
        }

        const commodityValue = commodityOptions[0].value;

        if (isPricesCategory) {
            props.handleChange(commodityValue.commodity, commodityValue.extraParameters ?? undefined, commodityValue.subRegion);
        } else if (isFuturesCategory && !props.extraParameters) {
            props.handleChange(commodityValue.commodity, `${defaultExtraParameters}`);
        } else if (isFuturesCategory && props.extraParameters) {
            props.handleChange(commodityValue.commodity, props.extraParameters);
        } else {
            props.handleChange(commodityValue.commodity, commodityValue.extraParameters ?? undefined);
        }
    }, [commodityOptions]);

    const optionValueSelected = useMemo(() => {
        if (!commodityOptions) {
            return undefined;
        }

        let commodityOptionMatches = commodityOptions
            .map((x) => x.value)
            .filter(
                (x) =>
                    x.commodity === props.commodity &&
                    (x.extraParameters === props.extraParameters || (!x.extraParameters && !props.extraParameters) || isFuturesCategory),
            );

        if (isPricesCategory && commodityOptionMatches.length > 1) {
            commodityOptionMatches = commodityOptionMatches.filter((x) => x.subRegion === props.subRegion);
        }

        return commodityOptionMatches.length > 0 ? commodityOptionMatches[0] : undefined;
    }, [commodityOptions, props.commodity, props.extraParameters, props.subRegion]);

    return !commodityOptions || commodityOptions?.length === 0 ? null : (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={optionValueSelected}
                options={commodityOptions}
                handleOptionChange={(value) => {
                    if (isPricesCategory) {
                        props.handleChange(value.commodity, value.extraParameters, value.subRegion);
                    } else if (isFuturesCategory) {
                        props.handleChange(value.commodity, props.extraParameters!);
                    } else {
                        props.handleChange(value.commodity, value.extraParameters);
                    }
                }}
                label={translations.marketIndicatorsManagement.fields.commodity}
            />
        </div>
    );
};

export default DataSelectorCommodityDropdown;
