import React from 'react';
import { DemeterFeatureType, DemeterPermissionType } from '../../../Generated/Raven-Demeter';
import PageHeader from '../../Components/Headers/PageHeader';
import Tabs from '../../Components/Navigation/Tabs/Tabs';
import useFeatureFlag from '../../Services/FeatureFlags/useFeatureFlagHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import usePermission from '../../Services/Permissions/usePermissionHook';
import styles from './AdministrationPage.module.scss';
import CompaniesPage from './Companies/CompaniesPage';
import MarketIndicatorsMangementPage from './MarketIndicatorsManagement/MarketIndicatorsManagementPage';
import UsersPage from './Users/UsersPage';

const AdministrationPage: React.FC = () => {
    const [translations] = useLanguage();
    const pageTitle = translations.administration.title;

    const tabOptions = [
        {
            title: translations.users.title.list,
            key: 'Users',
            component: <UsersPage />,
            showTab: useFeatureFlag(DemeterFeatureType.Users),
        },
        {
            title: translations.companies.title.list,
            key: 'Companies',
            component: <CompaniesPage />,
            showTab: useFeatureFlag(DemeterFeatureType.Companies),
        },
        {
            title: translations.marketIndicatorsManagement.title,
            key: 'MarketIndicators',
            component: <MarketIndicatorsMangementPage />,
            showTab:
                useFeatureFlag(DemeterFeatureType.MarketIndicators) &&
                (usePermission(DemeterPermissionType.MarketIndicatorAdministrator) ||
                    usePermission(DemeterPermissionType.MarketIndicatorDeveloper) ||
                    usePermission(DemeterPermissionType.MarketIndicatorCompliance) ||
                    usePermission(DemeterPermissionType.MarketIndicatorSubjectMatterExpert)),
        },
    ];

    return (
        <div className={styles.master_page_container}>
            <PageHeader title={pageTitle} testId="AdministrationPageHeader" />
            <Tabs tabOptions={tabOptions} />
        </div>
    );
};

export default AdministrationPage;
