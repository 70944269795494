/* eslint-disable @typescript-eslint/indent */
import { useMemo } from 'react';
import { demeterApi, demeterUserDataApi } from '../../../Apis/Apis';
import {
    CommodityPriceModel,
    Currency,
    DemeterDataFrequency,
    DemeterDataSource,
    DemeterFilterTimeSpan,
    DemeterRegion,
    DemeterSubRegion,
    DemeterTableDefinitionType,
    UnitOfMeasure,
} from '../../../Generated/Raven-Demeter/api';
import { IRegionCommoditySelection } from '../../Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Components/Navigation/Hooks/useTableDefinitionHook';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useCacheThenApi from './useCacheThenApiHook';

export interface ListCommodityPricesResponse {
    rows?: CommodityPriceModel[] | null;
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
    dataSourceTag?: string | null;
    oldestAsOfDate?: string | null;
    filterTimeSpan?: DemeterFilterTimeSpan | null;
}

const usePricesApi = (
    regionCommoditySelection: IRegionCommoditySelection,
    currency?: Currency,
    unitOfMeasure?: UnitOfMeasure,
    timeSpan?: DemeterFilterTimeSpan,
): ListCommodityPricesResponse | undefined => {
    const [, tableDefinitionCommodity] = useTableDefinition(DemeterTableDefinitionType.CommodityPricesTable, regionCommoditySelection);

    const cacheKey = useMemo(() => {
        const cacheKeyPrefix = `${CacheKeys.GetDemeterPrices}_${regionCommoditySelection.dataFrequency}`;
        if (!tableDefinitionCommodity || !regionCommoditySelection) {
            return cacheKeyPrefix;
        }

        const cacheKeySuffix = JSON.stringify({ ...regionCommoditySelection, currency, unitOfMeasure, timeSpan });
        return `${cacheKeyPrefix}_${cacheKeySuffix}`;
    }, [tableDefinitionCommodity, regionCommoditySelection, currency, unitOfMeasure, timeSpan]);

    const [, , listDemeterPricesResponse] = useCacheThenApi<ListCommodityPricesResponse>(cacheKey, () => {
        if (!tableDefinitionCommodity || !regionCommoditySelection || !regionCommoditySelection.region) {
            return null;
        }

        const dataSource = regionCommoditySelection.extraParameters ? (regionCommoditySelection.extraParameters as DemeterDataSource) : undefined;
        const subRegion = regionCommoditySelection.subRegion ? (regionCommoditySelection.subRegion as DemeterSubRegion) : undefined;

        if (regionCommoditySelection.region === DemeterRegion.UserData) {
            return demeterUserDataApi.listUserDataMonthlyPrices(
                regionCommoditySelection.extraParameters,
                currency as Currency,
                unitOfMeasure as UnitOfMeasure,
                timeSpan,
            );
        }

        if (regionCommoditySelection.dataFrequency === DemeterDataFrequency.Daily) {
            return demeterApi.listCommoditySpotPrices(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                dataSource,
                currency,
                subRegion,
                unitOfMeasure,
                timeSpan,
            );
        }

        if (regionCommoditySelection.dataFrequency === DemeterDataFrequency.Weekly) {
            return demeterApi.listCommodityWeeklyPrices(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                dataSource,
                currency,
                subRegion,
                unitOfMeasure,
                timeSpan,
            );
        }

        if (regionCommoditySelection.dataFrequency === DemeterDataFrequency.Monthly) {
            return demeterApi.listCommodityMonthlyPrices(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                dataSource,
                currency,
                subRegion,
                unitOfMeasure,
                timeSpan,
            );
        }

        return null;
    });

    return listDemeterPricesResponse;
};

export default usePricesApi;
