/* eslint-disable react/no-this-in-sfc */
import HighchartsReact from 'highcharts-react-official';
import HighStock, { Options, SeriesPieDataLabelsOptionsObject, SeriesPieOptions } from 'highcharts/highstock';
import { memo, useEffect, useMemo, useState } from 'react';
import { DemeterFilterTimeSpan, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import formattingService from '../../../Services/Formatting/FormattingService';
import languageService from '../../../Services/Language/LanguageService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { chartColors, defaultChartOptions, IChartDataSeries, IChartProps } from '../ChartDefinitions';
import styles from './DonutChart.module.scss';

export interface IDonutChartRawProps extends IChartProps {
    title: string;
    innerText?: string;
    donutSeries: IChartDataSeries[];
    filterTimeSpan: DemeterFilterTimeSpan;
    showPopout: boolean;
    unitOfMeasure?: UnitOfMeasure;
}

// Defalults.
const defaultChartName = 'DonutChart';
const defaultChartType = 'pie';
const defaultChartHeight = 50;
const defaultVerticalAlignment = 'middle';
const defaultSeriesChartSize = 100;
const defaultSeriesInnerSize = 45;

// Constants.
const innerSizeWithPopout = 35;
const seriesChartSizeWithPopout = 82;
const chartHeightWithPopout = 32;

const OriginsAndDestinationsChartRaw: React.FC<IDonutChartRawProps> = (props) => {
    // Application hooks.
    const [translations] = useLanguage();

    // Chart hooks.
    const originsAndDestinationsDefaultOptions = useMemo<Options>(
        () => ({
            ...defaultChartOptions,
            colors: chartColors.donutChartColors,
            chart: {
                type: defaultChartType,
                height: `${defaultChartHeight}%`,
            },
            subtitle: {
                text: '',
                verticalAlign: defaultVerticalAlignment,
                useHTML: true,
                floating: true,
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    allowPointSelect: true,
                    dataLabels: {
                        useHTML: true,
                        enabled: false,
                    },
                },
            },
            tooltip: {
                useHTML: true,
                pointFormat: '',
            },
            series: [
                {
                    name: defaultChartName,
                    data: [],
                    type: defaultChartType,
                    size: `${defaultSeriesChartSize}%`,
                    innerSize: `${defaultSeriesInnerSize}%`,
                } as SeriesPieOptions,
            ],
        }),
        [],
    );
    const [highchartOptions, setHighchartOptions] = useState<Options>(originsAndDestinationsDefaultOptions as Options);

    // Text hooks.
    // Note: Highcharts requires this templating to get around a requirement that these fields need to be strings even though
    // we have html set to true. Example from highcharts forum: https://www.highcharts.com/forum/viewtopic.php?t=48932.
    const toolTipText = useMemo(
        () => `<p class=${styles.donut_chart_value}>{point.y}</p>
            <p class=${styles.donut_chart_percent}>{point.percentage:.0f} %</p>
            <p class=${styles.donut_chart_value}>${translations.unitOfMeasure[props.unitOfMeasure!]}</p>`,
        [translations, props.unitOfMeasure],
    );

    useEffect(() => {
        const newOptions = { ...highchartOptions };
        const firstOfSeriesOptions = newOptions.series![0] as SeriesPieOptions;

        newOptions.subtitle!.text = props.innerText;
        newOptions.tooltip!.pointFormat = toolTipText;
        (newOptions.plotOptions!.pie!.dataLabels as SeriesPieDataLabelsOptionsObject).enabled = props.showPopout;

        // Popouts and downloads on donut charts are displayed with extra space to accommodate data labels.
        firstOfSeriesOptions.innerSize = props.showPopout ? `${innerSizeWithPopout}%` : `${defaultSeriesInnerSize}%`;
        firstOfSeriesOptions.size = props.showPopout ? `${seriesChartSizeWithPopout}%` : `${defaultSeriesChartSize}%`;
        newOptions.chart!.height = props.showPopout ? `${chartHeightWithPopout}%` : `${defaultChartHeight}%`;

        firstOfSeriesOptions.dataLabels = {
            formatter() {
                if (this.y! <= 1) {
                    return null;
                }

                return `<span class=${styles.donut_chart_lable_root}>
                    <p class=${styles.donut_chart_country_name}> ${languageService.translate(this.point.name)}</p> 
                    <span class=${styles.donut_chart_data_and_percentage_wrapper} >
                    <p class=${styles.donut_chart_value} >${formattingService.toFormattedNumber(this.y)} </p>
                    <p class=${styles.donut_chart_percent} >${Math.round(this.point.percentage!)} % </p> </span></span>`;
            },
        };

        firstOfSeriesOptions.data! = props.donutSeries.map((row, index) => {
            if (index === 0) {
                return null;
            }

            return { name: languageService.translate(row.label!), y: row.data[0].value };
        });

        setHighchartOptions(newOptions);
    }, [props.innerText, props.donutSeries, props.showPopout, props.filterTimeSpan]);

    return <HighchartsReact ref={props.chartReference} highcharts={HighStock} options={highchartOptions} containerProps={{ style: { height: '100%' } }} />;
};

export default memo(OriginsAndDestinationsChartRaw);
