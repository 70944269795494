import SearchIcon from '@mui/icons-material/Search';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';
import { demeterCompanyApi } from '../../../../Apis/Apis';
import AgGridBuilder from '../../../../Components/AgGridBuilder/AgGridBuilder';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import { DemeterCompanyModel, ListDemeterCompaniesResponse, SortDirection } from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import { SearchParameters } from '../../../../Redux/Slices/SystemSlice';
import useApi from '../../../Apis/Hooks/useApiHook';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import useSearchParameters from '../../../Components/Navigation/Hooks/useSearchParametersHook';
import { ApiListResponse } from '../../../Components/Tables/TableWrapper/ApiListResponse';
import TableWrapper from '../../../Components/Tables/TableWrapper/TableWrapper';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './CompaniesPage.module.scss';
import { companiesColumnOptions, companyColumnDefinitions, RendererParameters } from './CompaniesPageDefinitions';
import CompanyActions from './CompanyActions';

const sortColumns = ['companyName', 'email'];
type SortColumn = (typeof sortColumns)[number];

interface ListDemeterCompanyRequest {
    searchTerm?: string;
    sortColumn?: SortColumn;
    sortDirection?: SortDirection;
    take: number;
    skip: number;
}

const CompaniesPage: React.FC = () => {
    const [translations] = useLanguage();
    const [searchParameters, setSearchParameters] = useSearchParameters();
    const [listDemeterCompaniesRequest, setListDemeterCompaniesRequest] = useState<ListDemeterCompanyRequest>(() => ({
        searchTerm: searchParameters.searchTerm ?? '',
        sortColumn: searchParameters.sortColumn ?? 'companyName',
        sortDirection: (searchParameters.sortDirection as SortDirection) ?? SortDirection.Asc,
        take: applicationConstants.ItemsPerPage,
        skip: (searchParameters.skip as unknown as number) ?? 0,
    }));
    const [searchTermInput, setSearchTermInput] = useState<string>('');
    const [listDemeterCompaniesResponse, setListDemeterCompaniesResponse] = useState<ListDemeterCompaniesResponse>();
    const gridReference = useRef<AgGridReact>(null);

    useEffect(() => {
        setSearchTermInput(searchParameters?.searchTerm);
        setListDemeterCompaniesRequest({ ...listDemeterCompaniesRequest, searchTerm: searchParameters?.searchTerm, skip: 0 });
        refreshApi();
    }, [searchParameters]);

    useEffect(() => {
        const actionIndex = companyColumnDefinitions.findIndex((element) => element.field === 'actions');
        // call here Edit component and pass parameter as below, right now its dummy::
        companyColumnDefinitions[actionIndex].cellRenderer = (parameters: RendererParameters) => <CompanyActions company={parameters.data} />;
    }, []);

    const [, refreshApi, apiResponse] = useApi(() => {
        if (!listDemeterCompaniesRequest) {
            return null;
        }

        return demeterCompanyApi.listDemeterCompanies(
            listDemeterCompaniesRequest.searchTerm,
            listDemeterCompaniesRequest.sortColumn,
            listDemeterCompaniesRequest.sortDirection,
            listDemeterCompaniesRequest.take,
            listDemeterCompaniesRequest.skip,
            undefined,
        );
    });

    useEffect(() => {
        if (apiResponse?.rows) {
            apiResponse.rows = apiResponse?.rows.map((row: DemeterCompanyModel) => ({ ...row, id: row.demeterCompanyGuid }));
            setListDemeterCompaniesResponse(apiResponse);
        }
    }, [apiResponse]);

    // Have event listeners on the column headers for sorting.
    useEffect(() => {
        const headers: NodeListOf<Element> = document.querySelectorAll('.ag-header-cell');
        headers.forEach((header) => header.addEventListener('click', () => handleHeaderClick(header)));
        // Cloning and replacing allows me to remove the listener. Was having some issues
        // achieving this with a remove listener function call alone.
        return () =>
            headers.forEach((header) => {
                const headerClone = header.cloneNode(true);
                header?.parentNode?.replaceChild(headerClone, header);
            });
    }, [listDemeterCompaniesResponse, gridReference?.current]);

    const handleSearch = (event: KeyboardEvent) => {
        const { value } = event.target as HTMLInputElement;
        if (event.key === 'Enter') {
            const newSearchParameters: SearchParameters = {
                searchTerm: value as string,
                sortColumn: listDemeterCompaniesRequest.sortColumn as string,
                sortDirection: listDemeterCompaniesRequest.sortDirection as string,
                skip: listDemeterCompaniesRequest.skip as unknown as string,
                tab: 'Companies',
            };

            setSearchParameters(newSearchParameters);
            setListDemeterCompaniesRequest({ ...listDemeterCompaniesRequest, searchTerm: value, skip: 0 });
        }
    };

    const handleHeaderClick: (header: Element | any) => Promise<void> | EventListenerOrEventListenerObject | null = async (header) => {
        const currentColId = header.getAttribute('col-id');
        if (sortColumns.includes(currentColId)) {
            const newRequest = { ...listDemeterCompaniesRequest };

            if (newRequest.sortColumn === currentColId) {
                newRequest.sortDirection = newRequest.sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
            } else {
                newRequest.sortColumn = currentColId;
                newRequest.sortDirection = SortDirection.Asc;
            }

            setListDemeterCompaniesRequest(newRequest);
        }
    };
    const handlePagination = (apiListResponse: ApiListResponse) => {
        const newRequest = { ...listDemeterCompaniesRequest };
        newRequest.skip = apiListResponse.skip ?? 0;
        setListDemeterCompaniesRequest(newRequest);
        refreshApi();
    };

    return !listDemeterCompaniesResponse ? (
        <PageLoadingSpinner />
    ) : (
        <div>
            <div className={styles.companies_actions_row}>
                <div className={styles.companies_actions_search}>
                    <div className={styles.companies_search_area}>
                        <SearchIcon className={styles.companies_search_area_icon} />
                        <input
                            onKeyDown={handleSearch as any}
                            className={styles.companies_actions_row_input}
                            placeholder={translations.actions.search}
                            value={searchTermInput}
                            onChange={(event) => setSearchTermInput((event.target as HTMLInputElement).value)}
                        />
                    </div>
                </div>
                <div className={styles.companies_buttons_row}>
                    <LinkButton title={translations.actions.add} navigateTo={`${NavigationRoutes.CompaniesAdd}`} type={LinkButtonType.Blue} />
                </div>
            </div>
            <div className={styles.companies_table_container}>
                <TableWrapper apiListResponse={listDemeterCompaniesResponse as ApiListResponse} showPagination handlePagination={handlePagination}>
                    <AgGridBuilder
                        gridRef={gridReference}
                        rowData={listDemeterCompaniesResponse.rows!}
                        hasSaveColumnsState
                        columnDefinitions={companyColumnDefinitions}
                        defaultColumnDefinition={companiesColumnOptions}
                        gridHeightFull
                        domLayout="autoHeight"
                        testId="CompaniesTable"
                    />
                </TableWrapper>
            </div>
        </div>
    );
};

export default CompaniesPage;
