import { Options, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighStock from 'highcharts/highstock';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import scssVariables from '../../../../Config.module.scss';
import { Currency, DemeterDataFrequency, DemeterUserType, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectUserType } from '../../../../Redux/Slices/UserSlice';
import formattingService from '../../../Services/Formatting/FormattingService';
import {
    areaRangeChartType,
    chartColors,
    ChartContext,
    defaultChartOptions,
    defaultZoneAxis,
    IChartAreaRangeDataSeries,
    IChartDataSeries,
    IChartProps,
    lineChartType,
} from '../ChartDefinitions';
import chartService from '../ChartService';
import { IMarketIndicatorChartColorPalette } from './MarketIndicatorChartColorPalette';

export interface IMarketIndicatorChartRawProps extends IChartProps {
    lineSeries: IChartDataSeries[];
    areaRangeSeries?: IChartAreaRangeDataSeries[];
    currencies?: Currency[];
    unitOfMeasures?: UnitOfMeasure[];
    hideAreaRangeSeriesInLegend?: boolean;
    colorPalette?: IMarketIndicatorChartColorPalette;
}

const lineSeriesBufferPercent = 5;
const dayInMilliseconds = 24 * 3600 * 1000;

const MarketIndicatorChartRaw: React.FC<IMarketIndicatorChartRawProps> = (props: IMarketIndicatorChartRawProps) => {
    // Application hooks.
    const currentUserType = useApplicationSelector(selectUserType);

    // Chart hooks.
    const marketsChartDefaultOptions = useMemo<Options>(
        () => ({
            ...defaultChartOptions,
            xAxis: {
                type: 'datetime',
                labels: {
                    formatter() {
                        const context = this as unknown as { value: number };
                        return formattingService.toMonthShortYearFromUtc(context.value);
                    },
                },
                tickInterval: 30 * dayInMilliseconds,
            },
            yAxis: [
                {
                    title: { text: '' },
                    labels: {
                        format: '{value:{point.y: , .0f}',
                    },
                    maxPadding: lineSeriesBufferPercent / 100.0,
                    minPadding: lineSeriesBufferPercent / 100.0,
                },
            ],
            series: [],
        }),
        [],
    );

    const [highchartOptions, setHighchartOptions] = useState<Options>(marketsChartDefaultOptions);

    const getDataSeriesDefinitions = useCallback(() => {
        const areasDataSeries = props.areaRangeSeries?.map((areaSeries, index) => {
            const currentColor =
                props.colorPalette?.areaRangeColors && props.colorPalette.areaRangeColors[index]
                    ? props.colorPalette.areaRangeColors[index]
                    : chartColors.decileChartColors.find((x) => x.decileRank === areaSeries.decileRank)?.color ??
                      chartColors.alternateAreaColors[index] ??
                      scssVariables.black;

            return {
                ...chartService.getDataSeriesBase(areaSeries, areaRangeChartType, currentColor),
                yAxis: 0,
                showInLegend: !props.hideAreaRangeSeriesInLegend,
                data: areaSeries.data.map((item) => [item.asOfDate.getTime(), item.minimumValue, item.maximumValue]),
                tooltip: {
                    enabled: currentUserType === DemeterUserType.Administrator || currentUserType === DemeterUserType.BusinessOwner,
                    customTooltipPerSeries() {
                        const context = this as unknown as ChartContext;
                        return chartService.getAreaRangeTooltipText(context);
                    },
                },
                zIndex: 1,
            };
        });

        const linesDataSeries = props.lineSeries.flatMap((lineSeries, index) => {
            const currentColor =
                props.colorPalette && props.colorPalette.lineColors[index]
                    ? props.colorPalette.lineColors[index]
                    : chartColors.lineChartColors[index] ?? scssVariables.black;

            return [
                {
                    ...chartService.getDataSeriesBase(lineSeries, lineChartType, currentColor),
                    yAxis: lineSeries.yAxis ?? 0,
                    events: {
                        legendItemClick() {},
                    },
                    zoneAxis: defaultZoneAxis,
                    turboThreshold: 0,
                    data: lineSeries.data.map((item) => [item.asOfDate.getTime(), item.value]),
                    tooltip: {
                        enabled: currentUserType === DemeterUserType.Administrator || currentUserType === DemeterUserType.BusinessOwner,
                        customTooltipPerSeries() {
                            const context = this as unknown as ChartContext;
                            const minimumValue = Math.min(...props.lineSeries[0].data.map((x) => Math.abs(x.value)).filter((x) => !!x));
                            const numberOfDecimalPlaces = formattingService.getDisplayDecimalPlacesMinimumForCharts(minimumValue ?? 0);

                            return chartService.getTooltipText(context, {
                                dataFrequency: DemeterDataFrequency.Weekly,
                                displayDecimalPlacesMinimum: props.displayDecimalPlacesMinimum ?? numberOfDecimalPlaces,
                                displayDecimalPlacesMaximum: props.displayDecimalPlacesMaximum ?? numberOfDecimalPlaces,
                            });
                        },
                    },
                    zIndex: 2,
                },
            ];
        });

        return [...linesDataSeries, ...(areasDataSeries ?? [])];
    }, [props.areaRangeSeries, props.lineSeries, props.colorPalette]);

    // Main useEffect to update chart when props or data changes.
    useEffect(() => {
        if (props.lineSeries.length === 0) {
            return;
        }

        const dataSeries = getDataSeriesDefinitions();
        const minimumValue = Math.min(...props.lineSeries[0].data.map((x) => Math.abs(x.value)).filter((x) => !!x));
        const numberOfDecimalPlaces = formattingService.getDisplayDecimalPlacesMinimumForCharts(minimumValue ?? 0);
        const newOptions = {
            ...marketsChartDefaultOptions,
            ...{ series: dataSeries },
            ...{
                xAxis: {
                    ...marketsChartDefaultOptions.xAxis,
                    scrollbar: {
                        enabled: false,
                    },
                },
            },
            ...{ yAxis: [...(marketsChartDefaultOptions.yAxis as YAxisOptions[])!] },

            tooltip: {
                formatter() {
                    return (this as unknown as ChartContext).series.tooltipOptions.customTooltipPerSeries.call(this);
                },
            },
        };

        const maximumYAxis = Math.max(...props.lineSeries.map((x) => (x.yAxis ?? 0) as number));

        if (maximumYAxis > 0) {
            for (let index = 0; index < maximumYAxis; index += 1) {
                const currentYAxis = {
                    title: { text: '' },
                    labels: { format: `{value:{point.y: , .${numberOfDecimalPlaces}f}` },
                    opposite: index % 2 === 0,
                };

                newOptions.yAxis.push({ ...(marketsChartDefaultOptions.yAxis as YAxisOptions[])!, ...currentYAxis });
            }
        }

        props.unitOfMeasures?.forEach((x, index) => {
            const prefix = props.unitOfMeasures?.length && props.unitOfMeasures?.length > 1 ? `${dataSeries[index].name} - ` : '';
            (newOptions.yAxis as YAxisOptions[])[index].title!.text = `${prefix}${chartService.getCurrencyAndUnitOfMeasureText(x, props.currencies![index])}`;
            (newOptions.yAxis as YAxisOptions[])[index].labels!.format = `{value:{point.y: , .${numberOfDecimalPlaces}f}`;
        });

        setHighchartOptions(newOptions as Options);
    }, [
        props.lineSeries,
        props.areaRangeSeries,
        props.colorPalette,
        props.unitOfMeasures,
        props.currencies,
        props.displayDecimalPlacesMinimum,
        props.displayDecimalPlacesMaximum,
    ]);

    return <HighchartsReact ref={props.chartReference} highcharts={HighStock} options={highchartOptions} containerProps={{ style: { height: '100%' } }} />;
};

export default memo(MarketIndicatorChartRaw);
